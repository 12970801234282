import React,  { useEffect } from 'react'
import { navigate } from "@reach/router"
import '../components/404.css'


import { responsiveTitle1 } from '../components/typography.module.css'


const NotFoundPage = () => {

  useEffect(() => {
    navigate('/');
  }, []);
  
  return(
    <div className='wrapper'>
      <h1 className={responsiveTitle1}>Page not found</h1>
    </div>
  )
}

export default NotFoundPage
